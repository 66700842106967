input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgb(230, 230, 230);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 34px;
  width: 34px;
  border-radius: 3px;
  background: url("https://data.spottimetta.fi/favicon.ico") no-repeat;
  pointer-events: auto;

  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: rgb(230, 230, 230);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgb(230, 230, 230);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 34px;
  width: 34px;
  border-radius: 3px;
  pointer-events: auto;
  background: url("https://data.spottimetta.fi/favicon.ico") ;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: rgb(230, 230, 230);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: rgb(230, 230, 230);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 34px;
  width: 34px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: rgb(230, 230, 230);
}
input[type="range"]:focus::-ms-fill-upper {
  background: rgb(230, 230, 230);
}

.sliderContainer {
  max-width: 100vw;
  width: 600px;
  margin: auto;
  margin-top: 50px;
  border: solid rgb(189, 189, 189);
  border-width: 2px;
  border-radius: 40px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.value {
  width: 80px;
  text-align: end;
  display: inline-flex;
}

.button {
  width: 100px;
  height: 50px;
  background-color: rgb(235, 235, 235);
  border: 1px solid rgb(78, 78, 78);
  color: rgb(0, 0, 0);
  text-align: center;

  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: 0.5s;
}
.button:hover {
  background-color: rgb(133, 133, 133);
  color: white;
}
.inline {
  display: inline-flex;
}
.w80 {
  width: 80px;
}
.m5l {
  margin-left: 5px;
}
.m5r {
  margin-right: 5px;
}

.dualRange{
  position: absolute;
  right: 25px;
  max-width: 85vw;
}

#lowerSlider{
  z-index: 100;
  height: 0px;
}
#upperSlider{
  /* TÄÄ SLIDER JÄÄ TAUSTALLE*/
  height: 0px;
  z-index: 10;
}
#lowerSlider::-webkit-slider-runnable-track {
  width: 0%;
  height: 0px;
  margin-bottom: 8px;
  cursor: pointer;
  box-shadow: 0px transparent, 0px  transparent;
  background: transparent;
  border-radius: 0px;
  border: 0px solid transparent;
  pointer-events: none;
}
#upperSlider::-webkit-slider-runnable-track {
  width: 0%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px transparent, 0px  transparent;
  border-radius: 0px;
  border: 0px solid transparent;
  pointer-events: none;
}

#lowerSlider::-moz-range-track {
  width: 0;
  height: 0;
  cursor: pointer;
  pointer-events: none;
  box-shadow:  transparent,  transparent;
  background: transparent ;
  border-radius: 1.3px;
  border: 0px solid transparent;
  
}


#lowerSlider::-ms-track {
  width: 0%;
  height: 0px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 0px 0;
  color: transparent;
}
#lowerSlider::-ms-fill-lower {
  background: transparent;
  border: transparent;
  border-radius: 0px;
  box-shadow:  0px transparent, 0px  transparent;
}
#lowerSlider:focus::-ms-fill-lower {
  background: transparent;
}
#lowerSlider::-ms-fill-upper {
  background: transparent;
  border: 0px solid transparent;
  border-radius: 0px;
  box-shadow:  0px transparent, 0px  transparent;
}
#lowerSlider:focus::-ms-fill-upper {
  background: transparent;
}