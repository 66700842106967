table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  max-width: 900px;
  margin: auto;
  cursor: pointer;
}
.listTable {
  width: 90%;
  max-width: 900px;
}
@media only screen and (max-width: 500px) {
  .listTable {
    width: 98%;
  }
  .listTitle {
    font-size: 14pt !important;
  }
  .listDescription {
    font-size: 10pt !important;
  }
  thead {
    font-size: 10pt !important;
  }
  .tableUpdated {
    font-size: 10pt !important;
  }
  .listImage {
    width: 10vw !important;
    max-width: 90px;
  }
  .iconHeader {
    width: 10vw !important;
    max-width: 90px;
  }
}

input[type="text"]::placeholder {
  color: rgb(134, 134, 134);
  opacity: 1;
  transition: 0.5s;
}
input[type="text"]:focus::placeholder {
  color: rgb(107, 107, 107);
  opacity: 1;
}
input[type="text"]:hover::placeholder {
  color: rgb(107, 107, 107);
  opacity: 1;
}
thead:hover {
  background-color: rgb(245, 245, 245);
  transition: 0.5s;
}

input[type="text"] {
  outline: 0;
  border-width: 0 0 1px;
  border-color: #dddddd;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  max-width: 900px;
  transition: 0.5s;
  padding-left: 5px;
}
input[type="text"]:focus {
  border-color: rgb(172, 172, 172);
}
input[type="text"]:hover {
  border-color: rgb(172, 172, 172);
}
.listDescription {
  color: rgb(109, 109, 109);
  padding-bottom: 0px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 8px;
}

.listImage {
  width: 80px;
}
.iconHeader {
  max-width: 80px;
  overflow-wrap: break-word;
  word-break: break-all;
}
.tableUpdated {
  width: 80px;
  max-width: 80px ;
}

.listTitle {
  display: block;
  width: 100%;
  font-size: 17pt;
  font-family: "Lilita One", cursive;
}
.tableRow {
  background-color: white;
  transition: 0.5s;
}
.tableRow:hover {
  background-color: rgba(204, 255, 204, 0.99);
}

.todo:hover {
  background-color: rgb(255, 196, 196);
}
