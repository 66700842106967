.dataBox * {
  margin: 10px;
  overflow-wrap: break-word;
}

.dataBox {
  background-color: rgb(250, 250, 250);
  margin: auto;
  margin-bottom: 30px;
  border: solid rgb(172, 172, 172);
  border-style: ridge;
  border-width: 2px;
  border-radius: 20px;
  width: 90%;
  max-width: 300px;
  cursor: pointer;
  transition: 0.5s;
}
.dataBox:hover {
  background-color: rgb(214, 214, 214);
  border: solid rgb(172, 172, 172);
  border-style: ridge;
  border-width: 2px;
}

.dataBox p {
  text-align: justify;
  color: rgb(100, 100, 100);
  width: auto;
}
.dataBox:hover p {
  color: rgb(0, 0, 0);
}
.margin {
  margin: 50px;
  margin-top: 5px;
}
