*,
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
p {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.bodyText {
  width: 90%;
  max-width: 700px;
  margin: auto;
}
h2 {
  display: block;
  width: 100%;
  font-size: xx-large;
  font-family: "Lilita One", cursive;
}

.App {
  text-align: center;
}

.m20b {
  margin-bottom: 20px;
}
.m50t {
  margin-top: 50px;
}
.wrap {
  overflow-wrap: break-word;
}
.sidePadding50{
  padding-left: 50px;
  padding-right: 50px;
}

.sideMargin50{
  margin-left: 50px;
  margin-right: 50px;
}

.wrap_content{
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a{
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}